
.tc-risk-catastrophic-wrap-multiple {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.tc-risk-catastrophic {
  padding-bottom: 1rem;
}

.tc-risk-catastrophic-item {
  padding-bottom: .5rem;
}

.tc-risk-catastrophic-item-icon {
  flex: 0 0 auto;
  padding-right: 1rem;
  color: #888888;
}

.tc-risk-catastrophic-item-name {
  padding-bottom: .5rem;
}

@media (max-width: 1200px) {
  .tc-risk-catastrophic-wrap-multiple {
    display: block;
  }
  .tc-risk-catastrophic-item {
    display: block;
    padding-bottom: 1rem;
  }
  .tc-risk-catastrophic-item-name {
    padding-bottom: .5rem;
  }
  .tc-risk-catastrophic-item-icon {
    padding-bottom: .5rem;
  }
}


.tc-risk-catastrophic-item-indication {
  display: inline-block;
  padding-left: 1rem;
}

.tc-risk-catastrophic-item-upside .tc-risk-catastrophic-item-indication {
  color: #43a047;
}

.tc-risk-catastrophic-item-downside .tc-risk-catastrophic-item-indication {
  color: #e53935;
}

.tc-risk-catastrophic-item-downside-medium .tc-risk-catastrophic-item-indication {
  color: #ff9800;
}

.tc-risk-catastrophic-item-downside-extreme .tc-risk-catastrophic-item-indication {
  font-weight: bold;
}
