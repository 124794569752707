
.tc-logo {
  width: 48px;
  height: 48px;
  padding-right: 12px;
}

.tc-logo-wrap {
  display: flex;
  align-items: center;
}

.tc-logo-text a {
  color: white;
  text-decoration: none;
}

.tc-header-stetch {
  flex: 1;
}

@media (max-width: 800px) {
  .tc-logo-wrap {
    flex: 1;
    text-align: center;
  }
  .tc-header-stetch {
    flex: 0;
  }
}
