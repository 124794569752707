
.tc-summary-charts {
  padding-right: 1rem;
}

.tc-summary-charts-metric {
  display: flex;
}

.tc-summary-charts-metric strong {
  flex: 1;
}

.tc-summary-charts-metric span {
  flex: 0 0 auto;
}

.tc-summary-chart-wrap {
  padding: .5rem 0;
}

.tc-summary-chart-progression {
  display: flex;
  padding-bottom: .5rem;
}

.tc-summary-chart-progression strong {
  display: block;
  padding-bottom: .2rem;
}

.tc-summary-chart-progression-begin {
  flex: 0 0 auto;
  text-align: left;
}

.tc-summary-chart-progression-arrow {
  flex: 1;
  text-align: center;
}

.tc-summary-chart-progression-end {
  flex: 0 0 auto;
  text-align: right;
}

.tc-summary-charts-columns {
  display: flex;
}

.tc-summary-charts-top {
  padding-bottom: 1rem;
}

.tc-summary-charts-column1 {
  width: 50%;
  box-sizing: border-box;
  padding-right: .5rem;
}

.tc-summary-charts-column2 {
  width: 50%;
  box-sizing: border-box;
  padding-left: .5rem;
}

.tc-summary-charts-column-item {
  padding-top: 1rem;
}

@media (max-width: 1200px) {
  .tc-summary-charts {
    padding-left: 0;
    padding-bottom: 1rem;
    padding-right: 0;
  }
}

@media (max-width: 800px) {
  .tc-summary-charts-columns {
    display: block;
  }
  .tc-summary-charts-column1 {
    width: auto;
    padding: 0 0 1rem 0;
  }
  .tc-summary-charts-column2 {
    width: auto;
    padding: 0;
  }
}
