
.tc-signup-form-wrap {
  padding-top: 1rem;
}

.tc-signup-header {
  padding-bottom: 1.5rem;
}

.tc-signup-field {
  padding-bottom: 1.5rem;
  max-width: 500px;
}
