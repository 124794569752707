
.tc-footer {
  background: black;
  color: white;
  display: flex;
  padding-right: 4rem;
  position: relative;
  margin-top: 4rem;
}

.tc-footer-copyright {
  flex: 1;
  padding: 2rem 3rem;
}

.tc-footer-image img {
  width: 650px;
  flex: 0 0 auto;
}

.tc-footer-gradient {
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 4rem;
}

@media (max-width: 800px) {
  .tc-footer {
    display: block;
    padding-right: 0;
  }
  .tc-footer-mobile-image {
    text-align: center;
  }
  .tc-footer-mobile-image img {
    width: 80%;
  }
  .tc-footer-copyright {
    text-align: center;
    line-height: 1.5rem;
  }
}

@media (max-width: 1400px) {
  .tc-footer-image img {
    width: 450px;
  }
}
