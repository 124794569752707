
.tc-risk-top {
  padding-bottom: 1rem;
}

.tc-risk-top-item {
  padding-bottom: .5rem;
}

.tc-risk-top-item-icon {
  padding-right: 1rem;
  color: #888888;
}

.tc-risk-top-item-name {
  padding-bottom: .5rem;
}

.tc-risk-top-item-indication {
  display: inline-block;
  padding-left: .5rem;
}

.tc-risk-top-item-extreme .tc-risk-top-item-indication {
  color: #e53935;
  font-weight: bold;
}

.tc-risk-top-item-high .tc-risk-top-item-indication {
  color: #e53935;
  font-weight: normal;
}

.tc-risk-top-item-medium .tc-risk-top-item-indication {
  color: #ff9800;
  font-weight: normal;
}

.tc-risk-top-item-low .tc-risk-top-item-indication {
  color: #43a047;
  font-weight: normal;
}
