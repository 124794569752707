
.tc-stock-header {
  display: flex;
  align-items: center;
}

.tc-stock-title {
  padding: 2rem 1rem 1rem 0;
}

.tc-stock-header-stretch {
  flex: 1;
}

.tc-stock-header-controls {
  flex: 0 0 auto;
}
