
.tc-summary-chart-dispersal-item {
  padding-top: 1.5rem;
}

.tc-summary-chart-dispersal-item-singular {
  padding-top: .5rem;
}

.tc-summary-chart-dispersal-stat {
  padding-top: .5rem;
  display: flex;
  justify-content: space-between;
}

.tc-summary-chart-dispersal-stat-name {
  color: #AAAAAA;
}

.tc-summary-chart-dispersal-nodata {
  padding-top: .5rem;
}
