
.tc-summary-chart-price-item {
  padding-top: 1.5rem;
}

.tc-summary-chart-price-stats {
  padding-top: .5rem;
}

.tc-summary-chart-price-stat {
  padding-top: .5rem;
  display: flex;
  justify-content: space-between;
}

.tc-summary-chart-price-stat-name {
  color: #AAAAAA;
}
