
.tc-stock-summary-glance {
  display: flex;
  align-items: center;
}

.tc-stock-summary-glance-symbol {
  flex: 0 0 100px;
  width: 50px;
  color: white;
  font-size: 1.5rem;
  vertical-align: middle;
}

.tc-stock-summary-glance-symbol-metrics {
  flex: 1;
}

@media (max-width: 800px) {
  .tc-stock-summary-glance {
    padding-top: 1rem;
    display: block;
  }

  .tc-stock-summary-glance-symbol {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .tc-stock-summary-glance-symbol-metrics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .tc-stock-summary-glance .tc-stock-metric {
    margin: 0;
    width: auto;
  }
}
