
.tc-explainer-item {
  display: flex;
  padding-bottom: .5rem;
}

.tc-explainer-item strong {
  flex: 1;
}

.tc-explainer-item span {
  flex: 0 0 auto;
}

.tc-explainer-item-accounting {
  border-top: 2px solid white;
  padding-top: .5rem;
}
