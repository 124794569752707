
.tc-signin-form-wrap {
  padding-top: 1rem;
  max-width: 600px;
  margin: auto;
}

.tc-signin-header {
  padding-bottom: 1.5rem;
}

.tc-signin-field {
  padding-bottom: 1.5rem;
  max-width: 500px;
}
