
.tc-header-mobile-menu {
  display: none;
  padding-right: 1rem;
}

.tc-header-mobile-menu-logo {
  text-align: center;
  font-size: 0.8rem;
  display: block;
  color: #AAAAAA;
}

@media (max-width: 1200px) {
  .tc-header-mobile-menu {
    display: block;
  }
}
