
.tc-stock-summary-disclaimer {
  padding-bottom: 1rem;
}

.tc-stock-summary-disclaimer .MuiTypography-root.MuiTypography-body2 {
  color: #666666;
}

.tc-stock-summary-disclaimer .MuiTypography-root.MuiTypography-body2 a {
  color: #666666;
}
