
.tc-expandcard-button {
  display: none;
}

@media (max-width: 800px) {
  .tc-expandcard-wrap-not-expanded .tc-expandcard-content {
    height: 200px;
    overflow: hidden;
  }

  .tc-expandcard-wrap-not-expanded .tc-expandcard-inner {
    position: relative;
    margin: -1rem -1rem 0 -1rem;
    padding: 1rem 1rem 0 1rem;
  }

  .tc-expandcard-wrap-not-expanded .tc-expandcard-gradient {
    background: linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, #121212 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .tc-expandcard-wrap-not-expanded .tc-expandcard-button {
    text-align: center;
    display: block;
  }
}

