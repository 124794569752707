
.tc-chart-preview-tooltip {
  background: #00000033;
  border-radius: .5rem;
  padding: .5rem;
}

.tc-chart-preview-tooltip-large {
  background: #000000DD;
}

.tc-chart-preview-tooltip strong {
  font-size: 1.25rem;
  display: block;
}

.tc-chart-preview-tooltip.tc-chart-preview-tooltip-multiple strong {
  font-size: 1rem;
}

.tc-chart-preview-tooltip-kicker {
  font-size: .75rem;
}
