
.tc-price-dial {
  padding-top: 1rem;
}

.tc-price-dial-indicator {
  position: relative;
  padding-bottom: 1rem;
}

.tc-price-dial-indicator-knob {
  position: absolute;
  background: #AAAAAA;
  box-shadow: 0px 3px 3px black;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  top: -6px;
}

.tc-price-dial-indicator-track {
  height: 1px;
  background: #666666;
}

.tc-price-dial-text {
  display: flex;
  justify-content: space-between;
}

.tc-price-dial-text-headline {
  font-size: .8rem;
  display: block;
  padding-bottom: .5rem;
}

.tc-price-dial-text-high {
  text-align: right;
}
