
.tc-stock-compare {
  padding-top: .5rem;
  color: white;
}

.tc-stock-compare-select {
  padding: 0.5rem 1rem;
}

.tc-stock-compare-select-wrap {
  width: 300px;
}
