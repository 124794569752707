
.tc-stock-risk-main {
  display: flex;
}

.tc-stock-risk-column-charts {
  flex: 0 0 400px;
  padding-right: 1rem;
}

@media (max-width: 1200px) {
  .tc-stock-risk-main {
    display: block;
  }

  .tc-stock-risk-column-charts {
    padding-right: 0;
  }
}
