
.tc-home-welcome {
  background: black;
  padding: 2rem 2rem 0 2rem;
  display: flex;
}

.tc-home-welcome-content {
  flex: 1;
  align-self: center;
}

.tc-home-welcome-kicker {
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 1rem;
}

.tc-home-welcome-kicker p.MuiTypography-root.MuiTypography-body1 {
  letter-spacing: .2rem;
}

.tc-home-welcome h1 {
  font-size: 4rem;
  letter-spacing: .2rem;
}

.tc-home-welcome-image {
  flex: 0 0 auto;
  align-self: flex-end;
  padding-right: 2rem;
}

.tc-home-welcome-image img {
  width: 650px;
}

.tc-home-welcome-description p.MuiTypography-root.MuiTypography-body1 {
  letter-spacing: .05rem;
  font-size: 1.5rem;
  padding-top: 1rem;
  color: #AAAAAA;
  padding-right: 4rem;
}

@media (max-width: 1400px) {
  .tc-home-welcome-image {
    padding-right: 2rem;
  }
  .tc-home-welcome-image img {
    width: 450px;
  }
  .tc-home-welcome-kicker {
    font-size: .75rem;
  }
  .tc-home-welcome h1 {
    font-size: 3rem;
  }
  .tc-home-welcome-description p.MuiTypography-root.MuiTypography-body1 {
    font-size: 1rem;
  }
}

@media (max-width: 1000px) {
  .tc-home-welcome {
    display: block;
    height: auto;
  }
  .tc-home-welcome-image {
    text-align: center;
    padding-top: 2rem;
    padding-right: 0;
  }
  .tc-home-welcome-image img {
    width: 80%;
    padding-right: 0;
  }
  .tc-home-welcome h1 {
    font-size: 2.5rem;
  }
}
