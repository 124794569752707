
.tc-header-desktop-auth {
  display: block;
}

.tc-header-desktop-auth-item {
  padding-right: 1rem;
  display: inline-block;
}

@media (max-width: 1200px) {
  .tc-header-desktop-auth {
    display: none;
  }
}
