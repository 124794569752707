
.tc-stock-summary-description-card-wrap {
  padding-bottom: 1rem;
}

.tc-stock-summary-sketches {
  padding-top: 1rem;
}

.tc-stock-summary-sketch {
  display: inline-block;
  width: 200px;
  vertical-align: top;
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
}

img.tc-stock-summary-sketch-image {
  width: 200px;
  height: 200px;
  border-radius: .25rem;
}

@media (max-width: 800px) {
  .tc-stock-summary-sketch {
    width: auto;
    margin-right: 0;
  }
  img.tc-stock-summary-sketch-image {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}
