
.tc-stock-metric {
  border: 1px solid black;
  border-radius: .4rem;
  display: inline-block;
  margin-right: 1rem;
  width: 9rem;
  cursor: default;
  margin-bottom: 1rem;
}

.tc-stock-metric-value {
  font-size: 2rem;
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  color: white;
}

.tc-stock-metric-name {
  background: black;
  color: white;
  font-weight: bold;
  padding: .35rem;
  text-align: center;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;
  height: 1.25rem;
}

.tc-stock-metric-name-icon {
  vertical-align: middle;
  display: inline-block;
  padding-left: .5rem;
}

.tc-stock-metric.tc-stock-metric-quality-great {
  border-color: #43a047;
}
.tc-stock-metric.tc-stock-metric-quality-great .tc-stock-metric-value {
  color: #43a047;
}
.tc-stock-metric.tc-stock-metric-quality-great .tc-stock-metric-name {
  background: #43a047;
}

.tc-stock-metric.tc-stock-metric-quality-good {
  border-color: #43a047;
}
.tc-stock-metric.tc-stock-metric-quality-good .tc-stock-metric-value {
  color: #43a047;
}
.tc-stock-metric.tc-stock-metric-quality-good .tc-stock-metric-name {
  background: #43a047;
}

.tc-stock-metric.tc-stock-metric-quality-neutral {
  border-color: #757575;
}
.tc-stock-metric.tc-stock-metric-quality-neutral .tc-stock-metric-value {
  color: #757575;
}
.tc-stock-metric.tc-stock-metric-quality-neutral .tc-stock-metric-name {
  background: #757575;
}

.tc-stock-metric.tc-stock-metric-quality-bad {
  border-color: #e53935;
}
.tc-stock-metric.tc-stock-metric-quality-bad .tc-stock-metric-value {
  color: #e53935;
}
.tc-stock-metric.tc-stock-metric-quality-bad .tc-stock-metric-name {
  background: #e53935;
}

.tc-stock-metric.tc-stock-metric-quality-terrible {
  border-color: #b71c1c;
}
.tc-stock-metric.tc-stock-metric-quality-terrible .tc-stock-metric-value {
  color: #b71c1c;
}
.tc-stock-metric.tc-stock-metric-quality-terrible .tc-stock-metric-name {
  background: #b71c1c;
}
