
.tc-stock-summary {
  padding-top: 2rem;
}

.tc-stock-summary-main {
  display: flex;
}

.tc-stock-summary-column-charts {
  flex: 0 0 650px;
}

.tc-stock-summary-column-about {
  flex: 1;
}

@media (max-width: 1200px) {
  .tc-stock-summary-main {
    display: block;
  }

  .tc-stock-summary-main-right {
    padding-top: 1rem;
  }
}


@media (max-width: 800px) {
  .tc-stock-summary {
    padding-top: 0;
  }
}
